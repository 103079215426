<template>
     <Layout>
         <PageHeader :title="title" :items="items"/>
         <div class="row">
             <div class="col-lg-12 mb-0">
                 <div class="card">
                     <div class="card-body">
                         <div class="float-sm-left">
                             <router-link
                                     to="/reviews"
                                     class="btn btn-info"
                             ><i class="mdi mdi-arrow-left-circle-outline mr-1"></i> Back
                             </router-link >
                         </div>
                     </div>
                 </div>
             </div>

             <div class="col-lg-4 col-xl-4 mx-0">
                 <div class="card">
                     <div class="card-body text-center">
                         <div class="avatar-xl mx-auto mt-1">
                             <div class="avatar-title bg-light rounded-circle">
                                 <!--                             <i class="mdi mdi-account h1 m-0 text-body"></i>-->
                                 <img :src="selectedReview.product.mainImage.imageUrl" alt="" width="100" height="100" class="rounded-circle" />
                             </div>

                         </div>

                         <h4 class="mt-3 mb-0">{{selectedReview.product.productName}} </h4>
                         <p class="text-muted"></p>

                         <button
                                 type="button"
                                 class="btn btn-danger btn-xs waves-effect mb-2 waves-light"
                         >
                             {{selectedReview.product.productName}}
                         </button>

                          <div class="text-left mt-3">
                              <h4 class="font-13 text-uppercase">About Product :</h4>
                              <p class="text-muted font-13 mb-3">
                                  {{selectedReview.product.productDescription}}
                              </p>

                              <div class="table-responsive">
                                  <table class="table table-borderless table-sm">
                                      <tbody>
                                      <tr>
                                          <th scope="row">Cost price :</th>
                                          <td class="text-muted">GHS {{selectedReview.product.costPrice}}  </td>
                                      </tr>
                                      <tr>
                                          <th scope="row">Selling price :</th>
                                          <td class="text-muted">GHS {{selectedReview.product.sellingPrice}}</td>
                                      </tr>
                                      <tr>
                                          <th scope="row">Expiry date :</th>
                                          <td class="text-muted"> {{ selectedReview.product.expiryDate | moment("MMMM Do YYYY")}}</td>
                                      </tr>
                                      <tr>
                                          <th scope="row">Quantity in stock :</th>
                                          <td class="text-muted">{{  selectedReview.product.quantity}}</td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                     </div>
                     <!-- end card-box -->
                 </div>
             </div>
             <!-- end col-->

             <div class="col-lg-8 col-xl-8 mx-0">
                 <div class="card">
                     <div class="card-body">
                         <h5 class="mb-3 text-uppercase bg-light text-primary rounded-lg p-2">
                             <i class="mdi mdi-alpha-i-circle-outline mr-1"></i>Review details
                         </h5>

                         <div class="row">
                             <div class="col-lg-12">
                                 <div class="form-group mb-3">
                                   <textarea rows="3"  class="form-control" readonly
                                                  v-model="selectedReview.review">
                                   </textarea>
                                 </div>
                             </div>
                             <div class="col-lg-6">
                                 <div class="form-group mb-3">
                                     <label for="ratings">Product rating</label>
                                     <input
                                             class="form-control"
                                             v-model="selectedReview.ratings"
                                             type="text"
                                             id="ratings"
                                             name="ratings"
                                             readonly
                                             v-validate="'required'"
                                             :class="{'is-invalid': errors.has('ratings') }"
                                     />
<!--                                     <span v-show="errors.has('review')" class="help text-danger">{{ errors.first('review') }}</span>-->
                                 </div>
                             </div>
                             <div class="col-lg-6">
                                 <div class="form-group mb-3">
                                     <label for="reviewDate">Review date</label>
                                     <input
                                             class="form-control"
                                             v-model="reviewDate"
                                             type="text"
                                             id="reviewDate"
                                             readonly
                                             v-validate="'required'"
                                             :class="{'is-invalid': errors.has('reviewDate') }"
                                     />
                                 </div>
                             </div>
                         </div>

                     </div>
                     <!-- end card-box-->
                 </div>
             </div>

         </div>


     </Layout>
</template>

<script>

    import Layout from "../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    import LottieLoader from "../../../components/LottieLoader";
    import moment from  'moment'

    export default {
        name: "ViewReviews",
        components : {
            Layout,
            PageHeader,
            LottieLoader
        },
        data(){
            return {
                selectedReview : {},
                 reviewDate :'',
                title: "View review",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "review",
                        active: true,
                    },
                ],
            }
        },
        methods : {
             getSelectedReviewById() {
                 BaseUrl.AxiosBearer.get(`/store/reviews/get-review/${this.$route.params.Id}`).then((resp) => {

                     if(resp.data.status) {
                         this.selectedReview = {}
                         this.selectedReview = resp.data.payload
                         this.reviewDate = moment(resp.data.payload.createdAt).format('DD MMMM YYYY')
                         console.log(" Selected review : ", this.selectedReview )
                     }
                 }).catch((error) => {
                     console.log("error : ", error.message)
                 })
             }
        },
        created() {
         this.getSelectedReviewById()
        }
    }
</script>

<style scoped>

</style>